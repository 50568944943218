<template>
  <div id="Configuration" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base">
      <v-row no-gutters class="titulo ml-10 mt-1 mb-n2"
        >CONFIGURACIÓN DE ANTICIPOS DE NOMINA</v-row
      >
      <v-container fluid class="addEnterprise">
        <v-row no-gutters justify="end">
          <v-col
            cols="12"
            lg="5"
            md="5"
            sm="5"
            class="marginCol configurationNomina"
          >
            <v-row class="mt-n5">
              <!--CONFIGURACION COMERCIAL O PAGADORA-->
              <div class="form-group">
                <label for="enterprise">La configuración se aplicará por</label>
                <select
                  name="enterprise"
                  id="enterprise"
                  v-model="enterprise"
                  @change="listar()"
                >
                  <option selected disabled value="0">
                    Selecciona Empresa
                  </option>
                  <option
                    v-for="enterprise in enterprises"
                    :key="enterprise.id"
                    v-bind:value="enterprise.id"
                  >
                    {{ enterprise.razonSocial }}
                  </option>
                </select>
              </div>
              <!--PORCENTAJE MAXIMO-->
              <div class="form-group">
                <label for="percentage">Porcentaje máximo de anticipo</label>
                <input
                  name="percentage"
                  id="percentage"
                  v-model="configuration.porcentajeMaximo"
                  class="inputs ml-n4 mr-1"
                  style="width: 135px"
                />
                %
              </div>
              <!--MONTO MAXIMO-->
              <div class="form-group">
                <label for="amount">Monto máximo de anticipo</label>

                <input
                  name="amount"
                  id="amount"
                  v-model="configuration.montoMaximo"
                  class="inputs ml-n4 mr-2"
                  style="width: 135px"
                />M.N
              </div>
              <!--TIEMPO MINIMO-->
              <div class="form-group">
                <label for="time"
                  >Tiempo mínimo de empleo para acceder a la prestación</label
                >
                <select
                  name="time"
                  id="time"
                  v-model="configuration.tiempoMinimo"
                >
                  <option disabled value="0">Selecciona tiempo</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option></select
                >MESES
              </div>
              <!--BOTON DE CORTE SEMANAL -->
              <v-row>
                <v-col>
                  <label for="semanal">Corte de nomina semanal</label>
                  <!--<v-btn
                    class="ml-n5"
                    id="semanal"
                    text
                    icon
                    color="#00287c"
                    @click="cargaSemanal()"
                  >
                    <v-icon>add_box</v-icon>
                  </v-btn>-->
                </v-col>
              </v-row>
              <!--LISTADO DE DE CORTE SEMANAL-->
              <v-row
                v-for="semana in arregloSemanal"
                v-bind:value="semana.id"
                :key="semana.id"
                class="mt-n4 mb-n4"
              >
                <v-col>
                  <label
                    for="diasSemanal"
                    style="display: inline-block; width: 40px !important"
                    >Será</label
                  >
                  <select
                    name="diasSemanal"
                    id="diasSemanal"
                    class="mr-4"
                    v-model="configuration.corteSemanal"
                  >
                    <option disabled value="0">Selecciona Días</option>
                    <option
                      v-for="dia in dias"
                      :key="dia.id"
                      v-bind:value="dia.id"
                    >
                      {{ dia.dia }}
                    </option></select
                  >Días antes de la fecha de pago
                </v-col>
              </v-row>
              <!--BOTON DE CORTE CATORCENAL -->
              <v-row>
                <v-col>
                  <label for="catorcenal">Corte de nomina catorcenal</label>
                </v-col>
              </v-row>
              <!--LISTADO CATORCENAL-->
              <v-row
                v-for="catorcena in arregloCatorcenal"
                v-bind:value="catorcena.id"
                :key="catorcena.id"
                class="mt-n4 mb-n2"
              >
                <v-col>
                  <label
                    for="diasCatorncenal"
                    style="display: inline-block; width: 40px !important"
                    >Será</label
                  >
                  <select
                    name="diasCatorcenal"
                    id="diasCatorcenal"
                    class="mr-4 mb-n4"
                    v-model="configuration.corteCatorcenal"
                  >
                    <option disabled value="0">Selecciona Días</option>
                    <option
                      v-for="dia in dias"
                      :key="dia.id"
                      v-bind:value="dia.id"
                    >
                      {{ dia.dia }}
                    </option></select
                  >Días antes de la fecha de pago
                </v-col>
              </v-row>
              <!--BOTON DE CORTE QUINCENAL -->
              <v-row>
                <v-col>
                  <label for="quincenal">Corte de nomina quincenal</label>
                </v-col>
              </v-row>
              <!--LISTADO QUINCENAL-->
              <v-row
                v-for="quincena in arregloQuincenal"
                v-bind:value="quincena.id"
                :key="quincena.id"
                class="mt-n4 mb-n4"
              >
                <v-col>
                  <label
                    for="diasQuincenal"
                    style="display: inline-block; width: 40px !important"
                    >Será</label
                  >
                  <select
                    name="diasQuincenal"
                    id="diasQuincenal"
                    class="mr-4"
                    v-model="configuration.corteQuincenal"
                  >
                    <option disabled value="0">Selecciona Días</option>
                    <option
                      v-for="dia in dias"
                      :key="dia.id"
                      v-bind:value="dia.id"
                    >
                      {{ dia.dia }}
                    </option></select
                  >Días antes de la fecha de pago
                </v-col>
              </v-row>
              <!--BOTON DE CORTE MENSUAL -->
              <v-row>
                <v-col>
                  <label for="mensual">Corte de nomina mensual</label>
                </v-col>
              </v-row>
              <!--LISTADO MENSUAL-->
              <v-row
                v-for="catorcena in arregloCatorcenal"
                v-bind:value="catorcena.id"
                :key="catorcena.id"
                class="mt-n4 mb-n4"
              >
                <v-col>
                  <label
                    for="diasMensual"
                    style="display: inline-block; width: 40px !important"
                    >Será</label
                  >
                  <select
                    name="diasMensual"
                    id="diasMensual"
                    class="mr-4"
                    v-model="configuration.corteMensual"
                  >
                    <option disabled value="0">Selecciona Días</option>
                    <option
                      v-for="dia in dias"
                      :key="dia.id"
                      v-bind:value="dia.id"
                    >
                      {{ dia.dia }}
                    </option></select
                  >Días antes de la fecha de pago
                </v-col>
              </v-row>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="6" align-self="center">
            <v-row justify="center">
              <button class="botonAmarillo" @click="cancelar()">
                Cancelar
              </button>
              <button class="botonAmarilloDerecho" @click="validacion()">
                Guardar
              </button>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Configuración de nómina</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-sm-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
          <ul style>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Validación de datos-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Alerta</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-lg-center text-sm-center">
            ¿Esta seguro de almacenar el anticipo de nómina?
          </h3>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="error" text @click="aux()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Configuración de nómina</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="aux()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      errors: [],
      respuesta: "",
      confirmation: false,
      advice: false,
      error: false,
      configuration: {
        id: null,
        empresaId: null,
        porcentajeMaximo: null,
        montoMaximo: null,
        tiempoMinimo: null,
        corteSemanal: null,
        corteCatorcenal: null,
        corteQuincenal: null,
        corteMensual: null,
      },
      enterprise: localStorage.empresaIdGlobal,
      enterprises: [],
      corte: 0,
      arregloSemanal: [
        {
          id: 1,
          dias: 0,
        },
      ],
      arregloCatorcenal: [
        {
          id: 1,
          dias: 0,
        },
      ],
      arregloQuincenal: [
        {
          id: 1,
          dias: 0,
        },
      ],
      arregloMensual: [
        {
          id: 1,
          dias: 0,
        },
      ],
      semanal: 0,
      catorcenal: 0,
      quincenal: 0,
      mensual: 0,
      percentage: 0,
      amount: 0,
      time: 0,
      dia: 0,
      nuevoRegistro: true,
      dias: [
        {
          id: 1,
          dia: 1,
        },
        {
          id: 2,
          dia: 2,
        },
        {
          id: 3,
          dia: 3,
        },
      ],
    };
  },
  methods: {
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode > 31 && charCode < 48) || charCode > 57) {
        evt.preventDefault();
      } else return true;
    },

    validacion() {
      console.log("llegue a validar");
      this.errors = [];
      if (this.enterprise == "" || this.enterprise == 0) {
        this.errors.push("Se debe especificar la Empresa.");
      }
      if (
        this.configuration.porcentajeMaximo == "" ||
        this.configuration.porcentajeMaximo == 0
      ) {
        this.errors.push("Se debe especificar el porcentaje máximo.");
      }
      if (
        this.configuration.montoMaximo == "" ||
        this.configuration.montoMaximo == 0
      ) {
        this.errors.push("Se debe especificar el monto máximo.");
      }
      if (
        this.configuration.tiempoMinimo == "" ||
        this.configuration.tiempoMinimo == 0
      ) {
        this.errors.push("Se debe especificar el tiempo mínimo.");
      }
      if (this.errors.length == 0) {
        return (this.advice = true);
      } else {
        this.respuesta = "Por favor corrige el(los) siguiente(s) error:";
        this.error = true;
      }
    },
    cancelar() {
      this.configuration = {
        id: null,
        empresaId: null,
        porcentajeMaximo: null,
        montoMaximo: null,
        tiempoMinimo: null,
        corteSemanal: null,
        corteCatorcenal: null,
        corteQuincenal: null,
        corteMensual: null,
      };
      this.enterprise = 0;
      this.percentage = 0;
      this.amount = 0;
      this.time = 0;
      this.arregloSemanal = [];
      this.arregloCatorcenal = [];
      this.arregloQuincenal = [];
      this.arregloMensual = [];
      this.dia = 0;
      this.$router.push("/dashboard");
    },
    guardar() {
      this.advice = false;
      //console.log(this.semanal);
      if (this.nuevoRegistro) {
        this.show = true;
        axios
          .post(
            Server + "/anticiposNomina",
            {
              EmpresaId: this.enterprise,
              PorcentajeMaximo: parseFloat(this.configuration.porcentajeMaximo),
              MontoMaximo: parseFloat(this.configuration.montoMaximo),
              TiempoMinimo: parseInt(this.configuration.tiempoMinimo),
              CorteSemanal: this.configuration.corteSemanal,
              CorteCatorcenal: this.configuration.corteCatorcenal,
              CorteQuincenal: this.configuration.corteQuincenal,
              CorteMensual: this.configuration.corteMensual,
            },
            {
              headers: {
                Authorization: localStorage.token,
              },
            }
          )
          .then((response) => {
            //console.log(response);
            this.respuesta =
              "La configuración de la nómina fue guardada con éxito";
            this.show = false;
            this.confirmation = true;
            this.$router.push("/configuration");
          })
          .catch((e) => {
            console.log(e);
            this.show = false;
            if (e.response.status == 401) {
              this.expiration = true;
            } else {
              this.respuesta =
                "La configuración de la nómina no fue almacenada con éxito.";
              this.confirmation = true;
            }
          });
      } else {
        this.show = true;
        axios
          .put(
            Server + "/anticiposNomina/" + this.configuration.id,
            {
              Id: this.configuration.id,
              EmpresaId: this.enterprise,
              PorcentajeMaximo: parseFloat(this.configuration.porcentajeMaximo),
              MontoMaximo: parseFloat(this.configuration.montoMaximo),
              TiempoMinimo: parseInt(this.configuration.tiempoMinimo),
              CorteSemanal: this.configuration.corteSemanal,
              CorteCatorcenal: this.configuration.corteCatorcenal,
              CorteQuincenal: this.configuration.corteQuincenal,
              CorteMensual: this.configuration.corteMensual,
            },
            {
              headers: {
                Authorization: localStorage.token,
              },
            }
          )
          .then((response) => {
            //console.log(response);
            this.respuesta =
              "La configuración de la nómina fue actualizada con éxito";
            this.show = false;
            this.confirmation = true;
            this.$router.push("/configuration");
          })
          .catch((e) => {
            console.log(e);
            this.show = false;
            if (e.response.status == 401) {
              this.expiration = true;
            } else {
              this.respuesta =
                "La configuración de la nómina no fue actualizada con éxito.";
              this.confirmation = true;
            }
          });
      }
    },
    listar() {
      this.show = true;
      this.nuevoRegistro = true;
      this.configuration = {
        id: null,
        empresaId: null,
        porcentajeMaximo: null,
        montoMaximo: null,
        tiempoMinimo: null,
        corteSemanal: null,
        corteCatorcenal: null,
        corteQuincenal: null,
        corteMensual: null,
      };    
      this.percentage = 0;
      this.amount = 0;
      this.time = 0;      
      this.dia = 0;

      axios
        .get(Server + "/anticiposNomina/", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          var auxEmpresa = response.data;
          auxEmpresa.forEach((value, index) => {
            if (value.empresaId == this.enterprise) {
              this.nuevoRegistro = false;
              this.configuration.id = value.id;
              this.configuration.empresaId = value.empresaId;
              this.configuration.porcentajeMaximo = value.porcentajeMaximo;
              this.configuration.montoMaximo = value.montoMaximo;
              this.configuration.tiempoMinimo = value.tiempoMinimo;
              this.configuration.corteSemanal = value.corteSemanal;
              this.configuration.corteCatorcenal = value.corteCatorcenal;
              this.configuration.corteQuincenal = value.corteQuincenal;
              this.configuration.corteMensual = value.corteMensual;
            }
          });
          //console.log("esto es configuration");
          //console.log(this.configuration);
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    aux() {
      this.confirmation = false;
      this.configuration = [];
      this.enterprise = 0;
      this.percentage = 0;
      this.amount = 0;
      this.time = 0;
      this.arregloSemanal = [
        {
          id: 1,
          dias: 0,
        },
      ];
      this.arregloCatorcenal = [
        {
          id: 1,
          dias: 0,
        },
      ];
      this.arregloQuincenal = [
        {
          id: 1,
          dias: 0,
        },
      ];
      this.arregloMensual = [
        {
          id: 1,
          dias: 0,
        },
      ];
      this.dia = 0;
      this.$router.push("/dashboard");
    },
    obtenerEmpresas() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.enterprises = response.data;
          this.show = false;
          this.listar();
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    this.obtenerEmpresas();
  },
};
</script>